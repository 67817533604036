import React from 'react';
import styled from 'styled-components';
import {
  faFacebook,
  faInstagram,
  faPinterest,
  faSnapchat,
  faTiktok,
  faYoutube,
} from '@fortawesome/free-brands-svg-icons';
import { faEnvelope, faPodcast } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { maxWidth } from '~/styles/media';
import theme from '~/styles/styledComponentsTheme';
import LSTVLink from '../LSTVLink';

const Container = styled.div`
  background: ${theme.grey_5_0};
  width: 100%;
`;

const Inner = styled.div`
  margin: 0 auto;
  padding: 0 32px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: end;
  max-width: 1440px;
  @media ${maxWidth('sm')} {
    justify-content: center;
  }
`;

const MobileOnly = styled.a`
  display: flex;
  align-items: center;
  @media ${maxWidth('sm')} {
    display: none;
  }
`;

const Separator = styled.div`
  width: 1px;
  height: 27px;
  background: ${theme.grey_2_0};
  margin: 0 15px;
`;

const Icon = styled(FontAwesomeIcon)`
  margin: 15px;
`;

const TopBar: React.FC = () => {
  return (
    <Container>
      <a
        href="https://www.davidsbridal.com"
        style={{ display: "block", padding: "18px", background: "black", color: "white", textTransform: "uppercase", letterSpacing: "2px" }}
      >
        Return to David&apos;s Bridal
      </a>
      <Inner>
        <MobileOnly href="/subscribe">
          <Icon icon={faEnvelope} />
          Get Wedding Videos In Your Inbox
          <Separator />
        </MobileOnly>
        <LSTVLink to="https://www.youtube.com/channel/UC5ntpzpHROXC6lgBc6vuScQ">
          <Icon icon={faYoutube} />
        </LSTVLink>
        <LSTVLink to="https://www.pinterest.com/lovestoriestv/">
          <Icon icon={faPinterest} />
        </LSTVLink>
        <LSTVLink to="https://www.facebook.com/LoveStoriesTV/">
          <Icon icon={faFacebook} />
        </LSTVLink>
        <LSTVLink to="https://www.instagram.com/lovestoriestv/">
          <Icon icon={faInstagram} />
        </LSTVLink>
        <LSTVLink to="https://tiktok.com/@lovestoriestv">
          <Icon icon={faTiktok} />
        </LSTVLink>
        <LSTVLink to="https://www.snapchat.com/discover/Love_Stories_TV/9534865702">
          <Icon icon={faSnapchat} />
        </LSTVLink>
        <LSTVLink to="https://podcasts.apple.com/us/podcast/since-you-asked-a-podcast-on-your-wedding-questions/id1344248706?mt=2">
          <Icon icon={faPodcast} />
        </LSTVLink>
      </Inner>
    </Container>
  );
};

export default TopBar;
